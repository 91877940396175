<template>
  <nav class="navbar navbar-dark bg-success navbar-expand-sm">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <i class="fa fa-user-circle"></i> NewDeal JsonPlaceholder.Typicode.com
        Test</router-link
      >
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
};
</script>

<style scoped></style>
