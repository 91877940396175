<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <img
          src="../assets/loading_anim3.gif"
          alt=""
          class="d-block text-center m-auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Spinner',
};
</script>

<style scoped></style>
